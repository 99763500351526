import React from 'react';
import Layout from '../components/Layout/Layout';

const NotFoundPage = ({location: {pathname}}) => (
  <Layout pathname={pathname} incomingTitle={'Not Found'}>
    <h1>PAGE NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist.</p>
  </Layout>
);

export default NotFoundPage;